import { GET_ASS_SHIFT, UPDATE_ASS_SHIFT } from '../actions/ShiftAction';

const initialState = {
  allShifts: [],
  assignedShiftsEmployee: [],
  allAssignedShifts: [],
  employerShiftCount: 0,
  editShift: {},
  employeeContacts: [],
  loader: new Date(),
};

export const ShiftReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASS_SHIFT:
      return {
        ...state,
        assignedShiftsEmployee: action.payload,
      };
    case UPDATE_ASS_SHIFT: {
      let shifts = state.assignedShiftsEmployee.map((shift) => {
        if (shift.id === action.payload.id) {
          return action.payload;
        } else {
          return shift;
        }
      });

      return {
        ...state,
        assignedShiftsEmployee: shifts,
        loader: new Date(),
      };
    }
    default: {
      return state;
    }
  }
};
