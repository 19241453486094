import { ADD_TICKET, GET_ALL_MY_TICKETS } from '../actions/TicketAction';

var initialState = {
  tickets: [],
};

export function TicketReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TICKET: {
      return {
        ...state,
        tickets: [...state.tickets, action.payload],
      };
    }
    case GET_ALL_MY_TICKETS: {
      return {
        ...state,
        tickets: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
