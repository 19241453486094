import React from "react"
import heroThumbOne from "../../assets/images/helpara/heroimg.png"

function Header({ className }) {
  return (
    <div>
      <section className={`unoHR-hero-area ${className || ""}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="unoHR-hero-content">
                <span>The help you need is finally here !</span>
                <h1 className="heroheading">
                  All-in-one solution
                  <br /> to manage
                  <br />
                  Household Employees
                </h1>
                <p style={{ lineHeight: "2.5rem" }}>
                  helpara is a simple and easy to use household employees
                  management application that can be entirely run on smartphones
                  and tablets.
                  <br />
                  <strong style={{ color: "#505056" }}>
                    On-boarding | Task Manager | Timesheets | Payroll | Tax
                    Filings
                  </strong>
                  <ul className="butons">
                    <li style={{ marginTop: 10 }}>
                      <a href="#">
                        <i className="fab fa-apple" /> Download for iOS
                      </a>
                    </li>
                    <li style={{ marginTop: 10 }}>
                      <a className="item-2" href="#">
                        <i className="fab fa-google-play" /> Download for
                        Android
                      </a>
                    </li>
                  </ul>{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="unoHR-hero-thumb">
                <div
                  className="thumb wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <img src={heroThumbOne} alt="" />
                </div>
                <div
                  className="thumb-2 wow animated fadeInRight"
                  data-wow-duration="2000ms"
                  data-wow-delay="600ms"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Header
