import { db } from '../config/db';
import {
  client_url,
  dwollaApi,
  admin_url,
  subscriptionApi,
} from '../config/endPoints';
import axios from 'axios';
export const GET_PRIVACY_POLICY = 'GET_PRIVACY_POLICY';
export const GET_TERMS_CONDITIONS = 'GET_TERMS_CONDITIONS';

export const getPrivacyPolicy = ({ setLoading }) => {
  return (dispatch) => {
    try {
      setLoading(true);
      db.collection('terms')
        .doc('privacyPolicy')
        .onSnapshot(function (querySnapshot) {
          if (querySnapshot.data()) {
            const data = querySnapshot.data();
            dispatch({
              type: GET_PRIVACY_POLICY,
              payload: data,
            });
          }
          setLoading(false);
        });
    } catch (err) {
      console.error(err);
    }
  };
};

export const getTermsConditions = ({ setLoading }) => {
  return (dispatch) => {
    try {
      setLoading(true);
      db.collection('terms')
        .doc('termsConditions')
        .onSnapshot(function (querySnapshot) {
          if (querySnapshot.data()) {
            const data = querySnapshot.data();
            dispatch({
              type: GET_TERMS_CONDITIONS,
              payload: data,
            });
          }
          setLoading(false);
        });
    } catch (err) {
      console.error(err);
    }
  };
};

export const changeDirectDeposit = (data, setLoading) => {
  return () => {
    let is = false;
    let paymentMethod = data.paymentMethod;
    let emloyeruid = data.emloyeruid;

    if (paymentMethod === 'direct deposit') is = true;
    else if (paymentMethod === 'manual') is = false;

    let docRef = db.collection('users').doc(data.id);
    docRef
      .set({ isDirectDeposit: is }, { merge: true })
      .then(function () {
        let data = {
          paymentMethod,
          emloyeruid,
        };
        axios
          .post(
            `${client_url}${dwollaApi.employees_change_paymentMethod}`,
            data
          )
          .then(function (res) {
            if (res.data === 'successfully updated') {
              toast.success('Payment method changed successfully!');
            }
          })
          .catch(function (error) {
            toast.error('Error changing payment method. Try again!');
            console.error(error);
          })
          .finally(() => setLoading(false));
      })
      .catch(function (error) {
        toast.error('Error changing payment method. Try again!');
        console.error(error);
        setLoading(false);
      });
  };
};

export function updateFacilities(empArray, data, setLoading) {
  let batch = db.batch();
  empArray.forEach((element) => {
    var sfRef = db.collection('users').doc(element.id);
    batch.update(sfRef, data);
  });

  return () => {
    batch
      .commit()
      .then(function (res) {
        toast.success('Successfully updated!');
      })
      .catch((err) => {
        toast.error('Error occoured Try again!');
      })
      .finally(() => setLoading(false));
  };
}

export function changeSubscriptions(data, setSubscriptionLoader) {
  return () => {
    console.log(data);
    axios
      .post(`${admin_url}${subscriptionApi.change_subscription}`, data)
      .then((res) => {
        if (res.data == 'successfully Changed') {
          toast.success('Succefully changed Sucscription');
        } else {
          toast.error('Error occured. Please try again later');
        }
      })
      .catch(() => {
        toast.error('Error occured. Please try again later');
      })
      .finally(() => setSubscriptionLoader(false));
  };
}
