import {
  EMPLOYE_CHECKIN,
  EMPLOYE_CHECKOUT,
  START_BREAK,
  GETEMPLOYERATTENDANCE,
  SUBMIT_WEEK,
  END_BREAK,
  RECORED_ATTENDANCE,
} from '../actions/AttendanceAction';

var initialState = {
  empAttendances: [],
  employeeAttendances: [],
  loader: true,
  employeeDay: [],
  employeeCheckIn: {},
  empOldWeekStatus: false,
  submitedRecords: [],
  empManualRecords: [],
  weekStatus: [],
  breakData: [],
};

export function AttendanceReducers(state = initialState, action) {
  switch (action.type) {
    case RECORED_ATTENDANCE: {
      return {
        ...state,
        loader: new Date(),
      };
    }

    case EMPLOYE_CHECKIN: {
      return {
        ...state,
        employeeCheckIn: action.payload,
        loader: new Date(),
      };
    }

    case EMPLOYE_CHECKOUT: {
      return {
        ...state,
        loader: new Date(),
      };
    }
    case START_BREAK: {
      return {
        ...state,
        breakStatus: true,
        loader: new Date(),
      };
    }
    case END_BREAK: {
      return {
        ...state,
        breakStatus: false,
        loader: new Date(),
      };
    }
    case GETEMPLOYERATTENDANCE: {
      let oldWeekStatus;
      let empStatusArray = action.payload.currentWeekAtd;

      if (action.payload.currentWeekAtd) {
        var employeeCheckIn = action.payload.currentWeekAtd.filter(
          (item) => item.status === 'checkIn'
        );
        empStatusArray.sort(function (a, b) {
          return (
            new Date(a.checkInTime.toDate()) - new Date(b.checkInTime.toDate())
          );
        });
        oldWeekStatus = false;
        let status = '';
        if (employeeCheckIn.length > 0) {
          status = 'checkIn';
        } else {
          status = 'checkOut';
        }
      }
      if (action.payload.lastWeekAtd) {
        oldWeekStatus = true;
        action.payload.lastWeekAtd.sort(function (a, b) {
          return (
            new Date(a.checkInTime.toDate()) - new Date(b.checkInTime.toDate())
          );
        });
      }

      return {
        ...state,
        employeeCheckIn: employeeCheckIn?.[0],
        employeeDay: empStatusArray,
        empOldWeekStatus: oldWeekStatus,
        employeeAttendances: action.payload.datatoStore,
        loader: new Date(),
      };
    }
    case SUBMIT_WEEK: {
      return {
        ...state,
        employeeDay: [],
        loader: new Date(),
      };
    }
    default: {
      return state;
    }
  }
}
