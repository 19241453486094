import { UPDATE_EMPLOYEE } from '../actions/AuthActions';
import {
  GET_EMPLOYEE,
  GET_SYS_DOC,
  GET_EMP_PAYPERIOD,
  CREATE_PAYSTUB,
  GET_EMP_WK_STATUS,
  CHANGE_TIMEMODE,
} from '../actions/EmployerAction';

var initialState = {
  // loader: true,
  employees: [],
  employeeDay: [],
  loader: new Date(),
  payPeriod: [],
  allEmployees: [],
  sysDocs: [],
  newEmp: {},
  loading: true,
};

export function EmployerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE: {
      let ActiveEmployees = action.payload.filter(
        (emp) => emp.status === 'active'
      );
      return {
        ...state,
        employees: ActiveEmployees,
        allEmployees: action.payload,
        loader: new Date(),
        loading: false,
      };
    }
    case CHANGE_TIMEMODE: {
      const updatedEmployees = state.employees.map((employee) => {
        if (employee.employeeId === action.payload.empId) {
          return {
            ...employee,
            timeMode: action.payload.timeMode,
          };
        }
        return employee;
      });
      return {
        ...state,
        employees: updatedEmployees,
      };
    }
    case UPDATE_EMPLOYEE: {
      let getPayload = action.payload;
      const updatedEmployees = state.employees.map((employee) => {
        if (employee.employeeId === getPayload?.docid) {
          return {
            ...employee,
            [getPayload?.name]: getPayload?.value,
          };
        }
        return employee;
      });

      return {
        ...state,
        employees: updatedEmployees,
      };
    }
    case GET_SYS_DOC: {
      return {
        ...state,
        sysDocs: action.payload,
      };
    }
    case GET_EMP_PAYPERIOD: {
      return {
        ...state,
        payPeriod: action.payload,
      };
    }

    case CREATE_PAYSTUB: {
      let remainingPayPeriods = state.payPeriod.filter(
        (item) => item.id != action.payload
      );

      return {
        ...state,
        payPeriod: remainingPayPeriods,
      };
    }
    case GET_EMP_WK_STATUS: {
      let empStatusArray = action.payload;
      empStatusArray.sort(function (a, b) {
        return (
          new Date(a.checkInTime.toDate()) - new Date(b.checkInTime.toDate())
        );
      });

      return {
        ...state,
        employeeDay: empStatusArray,
      };
    }
    default:
      return state;
  }
}
