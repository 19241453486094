import { db } from '../config/db';
export const GIFT_CARDS = 'GIFT_CARDS';
export const sendGiftReward = (
  data,
  setModal,
  setComment,
  setDollars,
  setSendGiftTo,
  setSendGiftUserName,
  setLoading
) => {
  return () => {
    db.collection('rewards')
      .add(data)
      .then(() => {
        setComment('');
        setDollars(5);
        setSendGiftTo({});
        setSendGiftUserName('');
        setModal(false);
        toast.success('Reward Sent Successfully!');
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
};

export function getGiftCards(empId, setLoader) {
  return (dispatch) => {
    db.collection('rewards')
      .where('empId', '==', empId)
      .get()
      .then(function (querySnapshot) {
        let datatoStore = [];
        querySnapshot.forEach(function (doc) {
          const data = doc.data();
          const docId = doc.id;

          datatoStore.push({ ...data, docId });
          return datatoStore;
        });
        dispatch({
          type: GIFT_CARDS,
          payload: datatoStore,
        });
      })
      .catch(function (error) {
        console.error('Error occoured Try again!', error);
      })
      .finally(() => setLoader(false));
  };
}
