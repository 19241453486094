export const CHANGE_THEME_TO_DARK = 'CHANGE_THEME_TO_DARK';
export const CHANGE_THEME_TO_LIGHT = 'CHANGE_THEME_TO_LIGHT';
export const BOLOCK_SHADOW = 'BOLOCK_SHADOW';
export const SQUARED_BORDER = 'SQUARED_BORDER';
export const GET_THEME = 'GET_THEME';
export const SIDEBAR = 'SIDEBAR';

export function changeThemeToDark() {
  return {
    type: CHANGE_THEME_TO_DARK,
  };
}

export function changeThemeToLight() {
  return {
    type: CHANGE_THEME_TO_LIGHT,
  };
}
export function blockShadow() {
  return {
    type: BOLOCK_SHADOW,
  };
}

export function squaredBorder() {
  return {
    type: SQUARED_BORDER,
  };
}
export function getCurrentTheme(data) {
  return {
    type: GET_THEME,
    payload: data,
  };
}
export function sidebarHandeler() {
  return {
    type: SIDEBAR,
  };
}
