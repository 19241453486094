// extracted by mini-css-extract-plugin
export var blogImg = "Details-module--blogImg--c6f22";
export var cards = "Details-module--cards--800c8";
export var container = "Details-module--container--ec3ce";
export var container1 = "Details-module--container1--9755a";
export var contentBg = "Details-module--contentBg--7979d";
export var contentContainer = "Details-module--contentContainer--b8ee7";
export var date = "Details-module--date--be218";
export var heading = "Details-module--heading--c7b70";
export var iconContainer = "Details-module--iconContainer--045a7";
export var mainContainer = "Details-module--mainContainer--4e483";
export var share = "Details-module--share--0970e";
export var sideIcons = "Details-module--sideIcons--ffabb";
export var subContainer = "Details-module--subContainer--d0004";
export var tag = "Details-module--tag--a7f4b";
export var tagsContainer = "Details-module--tagsContainer--32ece";
export var thumbnailCont = "Details-module--thumbnailCont--46f1f";