import { client_url, payrollApi } from '../config/endPoints';
import { db } from '../config/db';
import axios from 'axios';
export const GET_EMPLOYEE = 'GET_EMPLOYEE';
export const GET_SYS_DOC = 'GET_SYS_DOC';
export const GET_EMP_PAYPERIOD = 'GET_EMP_PAYPERIOD';
export const GET_EMP_WK_STATUS = 'GET_EMP_WK_STATUS';
export const CREATE_PAYSTUB = 'CREATE_PAYSTUB';
export const CHANGE_TIMEMODE = 'CHANGE_TIMEMODE';

export function getEmployees(id, setLoading) {
  return (dispatch) => {
    setLoading(true);
    db.collection('users')
      .where('employeruid', '==', id)
      .onSnapshot(function (querySnapshot) {
        let datatoStore = [];
        querySnapshot.forEach(function (doc) {
          const data = doc.data();
          const id = doc.id;

          datatoStore.push({ id, ...data });
          return datatoStore;
        });

        dispatch({
          type: GET_EMPLOYEE,
          payload: datatoStore,
        });
        setLoading(false);
      });
  };
}
export function getSystemDocs() {
  return (dispatch) => {
    db.collection('systemdocuments').onSnapshot(
      function (querySnapshot) {
        let datatoStore = [];
        querySnapshot.forEach(function (doc) {
          const data = doc.data();
          const id = doc.id;
          datatoStore.push({ id, ...data });
        });
        dispatch({
          type: GET_SYS_DOC,
          payload: datatoStore,
        });
      },
      function (error) {
        console.error(error);
      }
    );
  };
}

export function getPayPariod(id, setLoader) {
  return (dispatch) => {
    db.collection('payperiod')
      .where('employeeUid', '==', id)
      .onSnapshot(
        function (querySnapshot) {
          let datatoStore = [];
          querySnapshot.forEach(function (doc) {
            const data = doc.data();
            const id = doc.id;
            datatoStore.push({ id, ...data });

            return datatoStore;
          });
          setLoader(false);
          dispatch({
            type: GET_EMP_PAYPERIOD,
            payload: datatoStore,
          });
        },
        function (error) {
          console.error(error);
          setLoader(false);
        }
      );
  };
}

export function payrollSubmit(payStubData, setLoading, goBackHandler) {
  return (dispatch) => {
    axios
      .post(`${client_url}${payrollApi.generate_payStubs}`, payStubData)
      .then(() => {
        toast.success('Payroll successfully created!');
        if (goBackHandler) {
          goBackHandler();
        }
        dispatch({
          type: CREATE_PAYSTUB,
          payload: payStubData.id,
        });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };
}

export function payrollSubmitForAllEmployee(id, setLoading) {
  return () => {
    axios
      .post(`${client_url}${payrollApi.generatePayStubsForAll}`, id)
      .then(() => {
        toast.success('Payroll successfully created for all employees!');
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };
}

export function getEmployeStatus(empUid, setLoading) {
  return (dispatch) => {
    db.collection('attendance')
      .where('employeeUid', '==', empUid)
      .onSnapshot(
        function (querySnapshot) {
          let datatoStore = [];
          querySnapshot.forEach((doc) => {
            let data = doc.data();

            const id = doc.id;
            datatoStore.push({ id, ...data });

            return datatoStore;
          });
          setLoading(false);

          dispatch({
            type: GET_EMP_WK_STATUS,
            payload: datatoStore,
          });
        },
        function (error) {
          console.error(error);
          setLoading(false);
        }
      );
  };
}

export function changeTimeMode(data, setLoading, setUpdateTimeMode) {
  return (dispatch) => {
    axios
      .post(`${client_url}${payrollApi.change_timeMode}`, data)
      .then((res) => {
        const dataToStore = {
          timeMode: data.timeMode,
          empId: data.empId,
        };
        dispatch({
          type: CHANGE_TIMEMODE,
          payload: dataToStore,
        });
        toast.success('Successfully changed!');
        setUpdateTimeMode(false);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setLoading(false));
  };
}
export function changeTime(data, setLoading, setUpdateTimeMode) {
  return (dispatch) => {
    db.collection('users')
      .doc(data.empDocId)
      .update({ timeMode: data.timeMode })
      .then(function (docRef) {
        const dataToStore = {
          timeMode: data.timeMode,
          empId: data.empId,
        };
        dispatch({
          type: CHANGE_TIMEMODE,
          payload: dataToStore,
        });
        toast.success('Successfully changed!');
        setUpdateTimeMode(false);
      })
      .catch(function (error) {
        toast.error(error.message);
      })
      .finally(() => setLoading(false));
  };
}
