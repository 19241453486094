import axios from 'axios';
import moment from 'moment';
import { client_url, payrollApi } from '../config/endPoints';
import { db } from '../config/db';
import firebase from 'firebase';
export const EMPLOYE_CHECKIN = 'EMPLOYE_CHECKIN';
export const EMPLOYE_CHECKOUT = 'EMPLOYE_CHECKOUT';
export const START_BREAK = 'START_BREAK';
export const END_BREAK = 'END_BREAK';
export const GETEMPLOYERATTENDANCE = 'GETEMPLOYERATTENDANCE';
export const SUBMIT_WEEK = 'SUBMIT_WEEk';
export const RECORED_ATTENDANCE = 'RECORED_ATTENDANCE';

export function recordAttendance(
  attendanceData,
  setAttendanceLoader,
  setUpdateStartTime,
  setUpdateEndTime
) {
  return (dispatch) => {
    db.collection('attendance')
      .add(attendanceData)
      .then(function (doc) {
        setAttendanceLoader(false);
        setUpdateStartTime({});
        setUpdateEndTime({});
        toast.success('Attendance Submit Successfully');
        dispatch({
          type: RECORED_ATTENDANCE,
          payload: attendanceData,
        });
      })
      .catch(function (error) {
        toast.error('Error occur, try again later');
      });
  };
}

export function updateAttendance(
  data,
  setAttendanceLoader,
  setUpdateStartTime,
  setUpdateEndTime
) {
  return (dispatch) => {
    db.collection('attendance')
      .doc(data.id)
      .update(data)
      .then(function (doc) {
        setAttendanceLoader(false);
        setUpdateStartTime({});
        setUpdateEndTime({});
        toast.success('Successfully Updated.');
        dispatch({
          type: RECORED_ATTENDANCE,
          payload: data,
        });
      })
      .catch(function (error) {
        toast.error('Error occoured Try again!');
      });
  };
}

export function employeCheckIn(checkInData) {
  return (dispatch) => {
    db.collection('attendance')
      .add(checkInData)
      .then(function (docRef) {
        const id = docRef.id;
        const dataToStore = { id, ...checkInData };
        dispatch({
          type: EMPLOYE_CHECKIN,
          payload: dataToStore,
        });
        toast.success('Successfully Checked-in');
      })
      .catch(function (error) {
        console.error(error);
        toast.error('Error occur, try again later');
      });
  };
}
export function employeCheckOut(
  checkOutData,
  setClockOutModal,
  setBreakData,
  setLunchBreakData
) {
  return (dispatch) => {
    db.collection('attendance')
      .doc(checkOutData.id)
      .update(checkOutData)
      .then(function (doc) {
        dispatch({
          type: EMPLOYE_CHECKOUT,
          payload: checkOutData,
        });
        setBreakData([]);
        setLunchBreakData([]);
        toast.success('Successfully Checked-out');
      })
      .catch(function (error) {
        console.error(error);
        toast.error('Error occur, try again later');
      })
      .finally(() => setClockOutModal(false));
  };
}

export function breakStart(data, id, setBreakLoader, setLunchLoader) {
  return (dispatch) => {
    db.collection('attendance')
      .doc(id)
      .update(data)
      .then(function (doc) {
        dispatch({
          type: START_BREAK,
        });
        setBreakLoader(false);
        setLunchLoader(false);
        toast.success('Successfully Started');
      })
      .catch(function (error) {
        console.error(error);
        toast.error('Error occur, try again later');
      });
  };
}
export function breakEnd(data, id, setBreakLoader, setLunchLoader) {
  return (dispatch) => {
    db.collection('attendance')
      .doc(id)
      .update(data)
      .then(function (doc) {
        dispatch({
          type: END_BREAK,
        });
        setBreakLoader(false);
        setLunchLoader(false);
        toast.success('Successfully Ended');
      })
      .catch(function (error) {
        toast.error('Error occur, try again later');
      });
  };
}
export function getAttendance(id, loading) {
  return async (dispatch) => {
    loading(true);
    db.collection('attendance')
      .where('employeeUid', '==', id)
      .onSnapshot(
        function (querySnapshot) {
          let datatoStore = [];
          querySnapshot.forEach(function (doc) {
            const data = doc.data();
            const id = doc.id;
            datatoStore.push({ id, ...data });
          });
          let lastWeekAtd = [];
          let currentWeekAtd = [];
          var prevMonday = new Date();
          prevMonday.setDate(
            prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7)
          );
          prevMonday.setHours(0, 0, 0, 0);
          querySnapshot.forEach((doc) => {
            let data = doc.data();

            const id = doc.id;
            if (data.checkInTime.toDate().getTime() >= prevMonday.getTime()) {
              currentWeekAtd.push({ id, ...data });
              return currentWeekAtd;
            } else {
              lastWeekAtd.push({ id, ...data });
              return lastWeekAtd;
            }
          });
          let data = {
            datatoStore,
            lastWeekAtd,
            currentWeekAtd,
          };
          dispatch({
            type: GETEMPLOYERATTENDANCE,
            payload: data,
          });
          loading(false);
        },
        function (error) {
          console.error(error);
          loading(false);
        }
      );
  };
}

export function submitRecord(submitData, setLoading, goBackHandler) {
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `${client_url}${payrollApi.create_payPeriod}`,
        submitData
      );
      toast.success(res.data);
      if (goBackHandler) {
        goBackHandler();
      }
      dispatch({
        type: SUBMIT_WEEK,
      });
    } catch (error) {
      console.log('error in submit record action', error);
    } finally {
      setLoading(false);
    }
  };
}
