import React, { useEffect } from "react"
import Navbar from "./navbar/Navbar"
import UseToggle from "../hooks/useToggle"
import Footer from "./footer/Footer"
import ScrollTop from "./ScrollTop"
import Drawer from "./drawer/Drawer"
import StickyMenu from "../lib/StickyMenu"
import "react-toastify/dist/ReactToastify.css"
export default function Layout({ children }) {
  const [drawer, drawerAction] = UseToggle(false)
  useEffect(() => {
    StickyMenu()
  })
  return (
    <div>
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <Navbar action={drawerAction.toggle} />
      <div>{children}</div>
      <Footer />
      <ScrollTop />
    </div>
  )
}
