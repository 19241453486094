import { Link } from "gatsby"
import React, { useRef, memo } from "react"
import blogOne from "../../assets/images/blog-1.jpg"
import blogTwo from "../../assets/images/blog-2.jpg"
import blogThree from "../../assets/images/blog-3.jpg"
//
const Blogs = memo(({ className, data }) => {
  let blog = data?.allContentfulResumasBlog?.edges
  return (
    <div>
      <section
        className={`appie-blog-area pt-30 pb-95 ${className || ""}`}
        id="blog"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title pb-40">Blog</h3>
              </div>
            </div>
          </div>
          <div className="row">
            {blog?.splice(0, 5).map(item => {
              return (
                <div className="col-lg-4 col-md-6">
                  <div
                    className="appie-blog-item mt-30 wow animated fadeInUp"
                    data-wow-duration="3000ms"
                    data-wow-delay="200ms"
                  >
                    <div className="thumb">
                      <img src={item.node.thumbnail.file.url} alt="" />
                    </div>
                    <div className="content">
                      <div className="blog-meta">
                        <ul>
                          <li>{item.node.updatedAt}</li>
                          <li>
                            <Link href="#" style={{ color: "#0e1133" }}>
                              {item.node.tags}
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <h3 className="title">
                        <Link
                          href={`/blogs/${item.node.slug}`}
                          style={{ color: "#0e1133" }}
                        >
                          {item.node.title}
                        </Link>
                      </h3>

                      <p>{item.node.shortDescription}</p>
                      <Link
                        href={`/blogs/${item.node.slug}`}
                        className="read-more"
                      >
                        Learn More <i className="fal fa-arrow-right" />
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })}
            {/*    <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-blog-item mt-30 wow animated fadeInUp"
                                data-wow-duration="3000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="thumb">
                                    <img src={blogTwo} alt="" />
                                </div>
                                <div className="content">
                                    <div className="blog-meta">
                                        <ul>
                                            <li  style={{color: '#0e1133'}}>March 10, 2022</li>
                                            <li>
                                                <a href="#"  style={{color: '#0e1133'}}>Saas & App</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <h3 className="title">
                                        <a href="/news/single-news"  style={{color: '#0e1133'}}>
                                            How to bring Dolce to your company
                                        </a>
                                    </h3>
                                    <a href="#"  style={{color: '#0e1133', margintop : '30px'}}>
                                        Learn More <i className="fal fa-arrow-right" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-blog-item mt-30 wow animated fadeInUp"
                                data-wow-duration="3000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="thumb">
                                    <img src={blogThree} alt="" />
                                </div>
                                <div className="contents"  style={{color: '#0e1133'}}>
                                    <div className="blog-meta">
                                        <ul  style={{color: '#0e1133'}}>
                                            <li  style={{color: '#0e1133'}}>March 10, 2022</li>
                                            <li>
                                                <a href="#"  style={{color: '#0e1133'}}>Saas & App</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <h3 className="title">
                                        <a href="/news/single-news"  style={{color: '#0e1133'}}>
                                            17 Effective Ways to Monetize Mobile Apps
                                        </a>
                                    </h3><div style={{margintop: '20px'}}> </div>
                                    <a href="#"  style={{color: '#0e1133'}}>
                                        Learn More <i className="fal fa-arrow-right" />
                                    </a>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </section>
    </div>
  )
}
)
export default Blogs
