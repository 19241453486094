import React, { useEffect } from 'react';
import TopToBottom from '../lib/TopToBottom';

function ScrollTop({ className }) {
    useEffect(() => {
        TopToBottom('.back-to-top');
    });
    return (
        <div>
            <div className={`back-to-top ${className || ''}`}>
                <a href="#">
                    <i className="fal fa-arrow-up" />
                </a>
            </div>
        </div>
    );
}

export default ScrollTop;
