exports.components = {
  "component---src-components-blogs-layout-blog-layout-js": () => import("./../../../src/components/blogsLayout/BlogLayout.js" /* webpackChunkName: "component---src-components-blogs-layout-blog-layout-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-ca-privacy-js": () => import("./../../../src/pages/CaPrivacy.js" /* webpackChunkName: "component---src-pages-ca-privacy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/PrivacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/Terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-third-party-js": () => import("./../../../src/pages/ThirdParty.js" /* webpackChunkName: "component---src-pages-third-party-js" */)
}

