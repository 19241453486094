import { db, auth, testFirebase } from '../config/db';
import axios from 'axios';
import { client_url, authApi } from '../config/endPoints';
export const ADD_NEW_EMPLOYEEERR = 'ADD_NEW_EMPLOYEEERR';
export const LOGIN = 'LOGIN';
export const GET_CURRENT_EMPLOYEE = 'GET_CURRENT_EMPLOYEE';
export const EMPLOYEELOGOUT = 'EMPLOYEELOGOUT';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const UPDATE_USER = 'UPDATE_USER';
export const ADD_NEW_EMPLOYEE = 'ADD_NEW_EMPLOYEE';
export const GET_EMPLOYEE_DATA = 'GET_EMPLOYEE_DATA';
export const MANAGE_EMP_PIN = 'MANAGE_EMP_PIN';

export function startLogin({ data, setLoader }) {
  return (dispatch) => {
    let collection = 'users';
    db.collection(collection)
      .where('email', '==', data.email)
      .get()
      .then(function (querySnapshot) {
        if (querySnapshot.empty) {
          toast.error('Email not found');
        }
        let datatoStore = {};
        querySnapshot.forEach(function (doc) {
          let data = doc.data();
          let docid = doc.id;
          let final = {
            ...data,
            docid,
          };
          datatoStore = final;
        });
        if (datatoStore.status === 'active') {
          auth
            .signInWithEmailAndPassword(data.email, data.password)
            .then((users) => {
              if (users) {
                toast.success('Successfully logged in');
              }

              auth.onAuthStateChanged((user) => {
                if (user) {
                  let data = {
                    ...datatoStore,
                    uid: user.uid,
                  };
                  dispatch({
                    type: LOGIN,
                    payload: data,
                  });
                } else {
                  console.error('error');
                }
              });
            })
            .catch((error) => {
              console.error(error);
              toast.error('Invalid Email or Password');
            });
        }
      })
      .finally(() => setLoader(false));
  };
}

export function registerEmployer(
  data,
  setLoader,
  navigateHandler,
  handleResetForm,
  setVerifiedEmail
) {
  return (dispatch) => {
    axios
      .post(`${client_url}${authApi.employer_singup}`, data)
      .then((res) => {
        if (res.data === 'successfully registered user') {
          dispatch({ type: ADD_NEW_EMPLOYEEERR });
          setLoader(false);
          setVerifiedEmail(false);
          navigateHandler && navigateHandler();
          handleResetForm && handleResetForm();
          toast.success('Successfully registered employer');
          localStorage.clear('activeStep');
        } else {
          console.error('An Error has Occurred! Please Try again', res);
          toast.error(res?.data?.message);
          setLoader(false);
        }
      })
      .catch((err) => {
        toast.error('An Error has Occurred! Please Try again');
        console.error(err);
        setLoader(false);
      });
  };
}

export function startGetCurrentUserEmployee(setUserLoading) {
  return (dispatch) => {
    try {
      setUserLoading(true);
      auth.onAuthStateChanged((user) => {
        if (user) {
          db.collection('users')
            .where('email', '==', user.email)
            .get()
            .then(function (querySnapshot) {
              let datatoStore = {};
              querySnapshot.forEach(function (doc) {
                let data = doc.data();
                let docid = doc.id;
                let final = {
                  ...data,
                  docid,
                };
                datatoStore = final;
              });

              let data = {
                ...datatoStore,
                uid: user.uid,
              };

              setUserLoading(false);
              dispatch({
                type: GET_CURRENT_EMPLOYEE,
                payload: data,
              });
            });
        } else {
          setUserLoading(false);
        }
      });
    } catch (err) {
      console.error(err);
      setUserLoading(false);
    }
  };
}
export function checkExistingEmail(verifyEmailHandler, email) {
  return () => {
    auth.fetchSignInMethodsForEmail(email).then((res) => {
      if (res.length) {
        toast.error('Email Address Is Already In Use!');
      } else {
        verifyEmailHandler();
      }
    });
  };
}

export function verifyEmail(data) {
  return () => {
    axios
      .post(`${client_url}/emails/sendMail`, data)
      .then((res) => {
        if (res.data === 'successfully sent') {
          // toast.success(res.data);
        } else {
          // dispatch({ type: CREATE_PAYSTUB_ERR });
        }
        //err
      })
      .catch((err) => {
        //err
        console.error('err', err);
      });
  };
}

export function startLogoutEmployee() {
  return (dispatch) => {
    auth.signOut().then(function () {
      toast.success('Successfully Logout');
      dispatch({
        type: EMPLOYEELOGOUT,
      });
    });
  };
}

export function updateEmployee(data, setModal, setLoader) {
  return (dispatch) => {
    db.collection('users')
      .doc(data.docid)
      .update({ [data.name]: data.value })
      .then(function () {
        toast.success('successfully updated');

        dispatch({
          type: UPDATE_EMPLOYEE,
          payload: data,
        });
        setLoader(false);
        setModal(false);
      })
      .catch(function (error) {
        console.error(error);
        toast.error('An Error Occurred!');
      });
  };
}

export function updateUser(data, docid, setModal, setLoader) {
  return (dispatch) => {
    db.collection('users')
      .doc(docid)
      .update(data)
      .then(function () {
        dispatch({
          type: UPDATE_USER,
          payload: data,
        });
        setLoader(false);
        setModal(false);
        toast.success('successfully updated');
      })
      .catch(function (error) {
        console.error(error);
        toast.error('An Error Occurred!');
      });
  };
}

export function startRessetPassword(
  data,
  setModal,
  setLoader,
  setUpdateField,
  goBackHandler
) {
  return () => {
    db.collection('users')
      .where('email', '==', data)
      .get()
      .then(function (querySnapshot) {
        if (querySnapshot.empty) {
          toast.error('Email not found');
          setLoader(false);
          setModal(false);
        } else {
          auth
            .sendPasswordResetEmail(data)
            .then(function () {
              toast.success('Please check your email');
              setLoader(false);
              setModal(false);
              if (goBackHandler) {
                goBackHandler();
              }
              setUpdateField('');
            })
            .catch(function (error) {
              toast.error('Check email and try again!');
            })
            .finally(function () {
              setLoader(false);
              setUpdateField('');
              setModal(false);
            });
        }
      });
  };
}

export function addNewEmployee(
  data,
  setLoading,
  setActiveStep,
  setSelectedDoc,
  setDocuments
) {
  return (dispatch) => {
    axios
      .post(`${client_url}${authApi.employee_invite}`, data)
      .then((res) => {
        if (res.data == 'successfully created employe') {
          dispatch({ type: ADD_NEW_EMPLOYEE, payload: data });
          setSelectedDoc([]);
          setDocuments([]);
          toast.success('Successfully created employee!');
        }
      })
      .catch((err) => {
        toast.error('Error Has been Occoured! Try again');
        console.error('err', err);
      })
      .finally(() => {
        setActiveStep && setActiveStep(0);
        setLoading(false);
      });
  };
}
export function employeeSignup(
  data,
  setLoader,
  handleResetForm,
  navigateHandler
) {
  return () => {
    setLoader(true);
    axios
      .post(`${client_url}${authApi.emoloyee_singup}`, data)
      .then((res) => {
        setLoader(false);
        // navigate('/');

        handleResetForm();
        if (navigateHandler) {
          navigateHandler();
        }
        toast.success('Successfully registered employee!');
      })

      .catch((err) => {
        toast.error('Error Has Occurred! Try again!');
        console.error('err', err);
      });
  };
}

export function getEmployeData(id, setVerifyEmpIdLoader, cb) {
  return (dispatch) => {
    db.collection('users')
      .where('employeeid', '==', id)
      .get()
      .then(function (querySnapshot) {
        let datatoStore = {};
        querySnapshot.forEach(function (doc) {
          let data = doc.data();

          let docid = doc.id;
          let final = {
            ...data,
            docid,
          };
          datatoStore = final;
        });
        if (cb) {
          cb(datatoStore);
        }
        setVerifyEmpIdLoader(false);

        dispatch({
          type: GET_EMPLOYEE_DATA,
          payload: datatoStore,
        });
      })
      .catch(function (error) {
        console.error(error);
        setVerifyEmpIdLoader(false);
      });
  };
}

//PIN user ;
export function managePIN(generatePIN, id, setPinLoading) {
  return (dispatch) => {
    const pin = generatePIN();

    db.collection('users')
      .where('pin', '==', pin)
      .get()
      .then(function (querySnapshot) {
        if (querySnapshot.empty) {
          db.collection('users')
            .doc(id)
            .update({ pin })
            .then(() => {
              setPinLoading(false);
              dispatch({
                type: MANAGE_EMP_PIN,
                payload: pin,
              });
              toast.success(`${pin} is your PIN.`);
            })
            .catch((e) => {
              setPinLoading(false);
              toast.error(`Error occured, try again later!`);
            });
        } else {
          dispatch(managePIN(generatePIN(), id));
        }
      })
      .catch((e) => {
        setPinLoading(false);
        toast.error(`Error occured, try again!`);
      });
  };
}
// login By Pin
export const loginByPin = ({ pin, setLoader }) => {
  return (dispatch) => {
    db.collection('users')
      .where('pin', '==', pin)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          toast.error('Invalid Pin');
        } else {
          let dataToStore = {};
          querySnapshot.forEach((doc) => {
            let data = doc.data();
            let docId = doc.id;
            let final = {
              ...data,
              docId,
              uid: data.uid,
            };
            dataToStore = final;
          });
          dispatch({
            type: LOGIN,
            payload: dataToStore,
          });
        }
      })
      .catch((e) => toast.error('Something went wrong Try Again Later'))
      .finally(() => setLoader(false));
  };
};
