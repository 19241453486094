import {
  CHANGE_THEME_TO_DARK,
  CHANGE_THEME_TO_LIGHT,
  SQUARED_BORDER,
  BOLOCK_SHADOW,
  GET_THEME,
  SIDEBAR,
} from '../actions/ThemeAction';

const initialState = {
  className: 'theme-light',
  squaredBorder: true,
  blockShadow: true,
  sidebarShow: true,
  sidebarCollapse: true,
};

export function ThemeReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_THEME_TO_DARK:
      return { ...state, className: 'theme-dark' };
    case CHANGE_THEME_TO_LIGHT:
      return { ...state, className: 'theme-light' };
    case SQUARED_BORDER:
      return { ...state, squaredBorder: !state.squaredBorder };
    case BOLOCK_SHADOW:
      return { ...state, blockShadow: !state.blockShadow };
    case SIDEBAR:
      return {
        ...state,
        sidebarShow: !state.sidebarShow,
        sidebarCollapse: !state.sidebarCollapse,
      };
    case GET_THEME:
      return action.payload;
    default:
      return state;
  }
}
