import {
  ADD_EMPLOYEE_OWN_TASK,
  GET_EMPLOYEE_OWN_TASK,
  DELETE_OWN_TASK,
  GET_TASK,
  ADD_TASK_EMPLOYEE,
  DELETE_TASK,
  COMPL_TASK,
  UPDATE_TASK,
  GET_ALL_LOG,
  VERIFY_PHONE_NO,
  VERIFY_OTP,
  GET_COMP_TASK,
} from '../actions/EmployeeTaskAction';

const initialState = {
  AllTask: [],
  PaperworkTask: [],
  CompletedTask: [],
  ownTask: [],
  logs: [],
  verificationText: '',
};

export const EmployeeTaskReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TASK:
      let tasksArray = action.payload;
      tasksArray.sort(function (a, b) {
        return new Date(b.PostedTime) - new Date(a.PostedTime);
      });

      return {
        ...state,
        AllTask: tasksArray,
      };

    case GET_COMP_TASK:
      let compTasksArray = action.payload;
      compTasksArray.sort(function (a, b) {
        return new Date(b.taskCompleted) - new Date(a.taskCompleted);
      });

      return {
        ...state,
        CompletedTask: compTasksArray,
      };

    case ADD_EMPLOYEE_OWN_TASK:
      return {
        ...state,
        ownTask: [action.payload, ...state.ownTask],
      };
    case GET_EMPLOYEE_OWN_TASK:
      let empownTaskArray = action.payload;
      empownTaskArray.sort(function (a, b) {
        return new Date(b.PostedTime) - new Date(a.PostedTime);
      });
      return {
        ...state,
        ownTask: empownTaskArray,
      };
    case DELETE_OWN_TASK:
      let newOwnTask = state.ownTask.filter(
        (item) => item.id != action.payload
      );
      return {
        ...state,
        ownTask: newOwnTask,
      };
    case ADD_TASK_EMPLOYEE:
      let newTaskAdeed = action.payload;
      return {
        ...state,
        employeeTask: { ...state.employeeTask, newTaskAdeed },
      };
    case DELETE_TASK:
      let newActiveTask = state.AllTask.filter(
        (item) => item.id !== action.payload
      );
      return {
        ...state,
        AllTask: newActiveTask,
      };
    case UPDATE_TASK: {
      let updateTask = state.AllTask.map((item) => {
        if (item.id == action.payload.id) {
          return action.payload;
        }
        return item;
      });
      return {
        ...state,
        AllTask: updateTask,
      };
    }
    // case COMPL_TASK:
    //   let newState = state.AllTask.filter(
    //     (item) => item.id != action.payload.id
    //   );
    //   return {
    //     ...state,
    //     AllTask: newState,
    //     CompletedTask: [...state.CompletedTask, action.payload],
    //   };
    case GET_ALL_LOG:
      action.payload.sort(function (a, b) {
        return new Date(b.taskCompleted) - new Date(a.taskCompleted);
      });
      return {
        ...state,
        logs: action.payload,
      };
    case VERIFY_PHONE_NO:
      return {
        ...state,
        verificationText: action.payload,
      };
    case VERIFY_OTP:
      return {
        ...state,
        ownTask: state.ownTask.filter((item) => !item.mobileVerification),
      };

    default:
      return state;
  }
};
