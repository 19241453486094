import React, { useState } from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
function Navigation() {
  const [login, setLogin] = useState(false)
  const location = useLocation()
  return (
    <div>
      <div className="navigationchange">
        <ul className="mr-2">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to={location.pathname !== "/" ? "/#features" : "#features"}>
              Features
            </Link>
          </li>
          <li>
            <Link to={location.pathname !== "/" ? "/#pricings" : "#pricings"}>
              Pricings
            </Link>
          </li>
          <li>
            <Link to={location.pathname !== "/" ? "/#faq" : "#faq"}>FAQ</Link>
          </li>
          <li>
            <Link to={location.pathname !== "/" ? "/#blog" : "#blog"}>
              Blog
            </Link>
          </li>
          <li>
            <Link to={"#contact"}>Contact</Link>
          </li>
          <li>
            <a
              className="login-btn"
              href="#"
              onClick={() => setLogin(value => !value)}
            >
              Login
            </a>
            {login && (
              <ul className="sub-menu">
                <li>
                  <a href="https://app.helpara.com/">Employer</a>
                </li>
                <li>
                  <a href="https://app.helpara.com/">Employee</a>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Navigation
