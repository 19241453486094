import {
  GET_EMPLOYEE_PAPER_WORK,
  ASSING_PAPERWORK,
  GET_ALL_MY_SUBMISSION,
  GET_PDF_RECORDS,
} from '../actions/PaperWorkAction';

var initialState = {
  documents: [],
  employerDocs: [],
  allEmployeeDocs: [],
  allEmployerDocs: [],
  pdfRecords: [],
  docsCount: 0,
  employeeDocsCount: 0,
  paperDocs: [],
  verifieddocuments: [],
  allSubmissions: [],
};

export function PaperWorkReducer(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_PAPER_WORK: {
      return {
        ...state,
        paperDocs: action.payload,
      };
    }
    case ASSING_PAPERWORK: {
      return {
        ...state,
        allEmployeeDocs: [...state.allEmployeeDocs, action.payload],
      };
    }
    case GET_ALL_MY_SUBMISSION: {
      return {
        ...state,
        allSubmissions: action.payload,
      };
    }
    case GET_PDF_RECORDS: {
      return {
        ...state,
        pdfRecords: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
