import { GET_PAY_STUB, GETEMPLOYERPAYSTUBS } from '../actions/PaystubAction';
var initialState = {
  paystubs: [],
  checkPayStubs: [],
  checkPayStubPDF: [],
  loading: 'asdf',
  employerPaystubs: [],
  employerPaystubsStatus: 'not done',
};

export function PaystubReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PAY_STUB: {
      let payStubArry = action.payload;
      payStubArry.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      return {
        ...state,
        paystubs: payStubArry,
      };
    }
    case GETEMPLOYERPAYSTUBS: {
      let payStubArry = action.payload;
      payStubArry.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      return {
        ...state,
        paystubs: payStubArry,
      };
    }
    default:
      return state;
  }
}
