// const url = 'https://unohr-server.herokuapp.com';
const url = 'http://localhost:3333';
export const admin_url =
  'https://us-central1-promising-saga-232017.cloudfunctions.net/adminApi';

// "http://localhost:5001/promising-saga-232017/us-central1/clientApi";
export const client_url = 'https://api.helpara.com/clientApi';
// 'http://10.0.2.2:3333/clientApi';
// 'http://localhost:3333/clientApi';
// 'https://us-central1-promising-saga-232017.cloudfunctions.net/clientApi';
//  "http://localhost:5001/promising-saga-232017/us-central1/clientApi";

export const dwollaApi = {
  customer_add_funding: '/dwolla/customer_Add_FundingSource',
  customer_transaction: '/dwolla/customer_Transactions',
  customer_detail: '/dwolla/customer_Details',
  funding_source_token: '/dwolla/fundingsource_token',
  varified_personal_customer: '/dwolla/verified_Personal_Customer',
  employees_change_paymentMethod: '/dwolla/employeesChangePaymentMethod',
};

export const authApi = {
  employer_singup: '/employer/signup',
  emoloyee_singup: '/employee/signup',
  employee_invite: '/employee/invite',
};
export const payrollApi = {
  create_payPeriod: '/payroll/createPayPeriod',
  change_timeMode: '/payroll/changeTimeMode',
  generate_payStubs: '/payroll/generatePayStubs',
  generatePayStubsForAll: '/payroll/generatePayStubsForAll',
};
export const taskApi = {
  add_task: '/tasks/addTask',
  server_task: '/tasks/serverTask',
  send_verification_code: '/tasks/sendVerificationCode',
  verify_otp: '/tasks/verifyOtp',
};
export const subscriptionApi = {
  apply_newSubscription: '/customers/applyNewSubscriptions',
  change_subscription: '/customers/changeSubscriptions',
  cancel_subscription: '/customers/cancelSubscriptions',
};
export const giftApi = {
  get_shopCard: '/gifts/getShopCards',
  send_reward: '/gifts/sendReward',
};
export const updateApi = '/apikeys/updateApi';
export const addNewReviewerApi = '/reviewer/addNewReviewer';

export default url;
