import axios from 'axios';
import { db } from '../config/db';
import { client_url, taskApi } from '../config/endPoints';
export const ADD_EMPLOYEE_OWN_TASK = 'ADD_EMPLOYEE_OWN_TASK';
export const GET_TASK = 'GET_TASK';
export const GET_COMP_TASK = 'GET_COMP_TASK';
export const GET_EMPLOYEE_OWN_TASK = 'GET_EMPLOYEE_OWN_TASK';
export const DELETE_OWN_TASK = 'DELETE_OWN_TASK';
export const ADD_TASK_EMPLOYEE = 'ADD_TASK_EMPLOYEE';
export const DELETE_TASK = 'DELETE_TASK';
export const COMPL_TASK = 'COMPL_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const GET_ALL_LOG = 'GET_ALL_LOG';
export const VERIFY_PHONE_NO = 'VERIFY_PHONE_NO';
export const VERIFY_OTP = 'VERIFY_OTP';
export const ADD_RECURRING_TASK = 'ADD_RECURRING_TASK';

export function addEmpOwnTask(
  data,
  setAddTaskModal,
  setAddTaskLoader,
  setTitle,
  setDescription,
  setTimestamp,
  navigateHandler
) {
  return (dispatch) => {
    db.collection('owntask')
      .add(data)
      .then(function (docRef) {
        const id = docRef.id;
        const dataToStore = { id, ...data };
        toast.success('Task added successfully');

        dispatch({
          type: ADD_EMPLOYEE_OWN_TASK,
          payload: dataToStore,
        });
        if (navigateHandler) {
          navigateHandler();
        }
        setAddTaskModal(false);
        setTitle('');
        setDescription('');
        setTimestamp('');
      })
      .catch(function (error) {
        console.error('Error occoured Try again!', error);
      })
      .finally(() => {
        setAddTaskLoader(false);
      });
  };
}

export function getEmployeeOwnTask({ setLoading, uid }) {
  return (dispatch) => {
    db.collection('owntask')
      .where('uid', '==', uid)
      .get()
      .then(function (querySnapshot) {
        let datatoStore = [];
        querySnapshot.forEach(function (doc) {
          const data = doc.data();
          const id = doc.id;
          datatoStore.push({ id, ...data });
          return datatoStore;
        });
        dispatch({
          type: GET_EMPLOYEE_OWN_TASK,
          payload: datatoStore,
        });
      })
      .catch(function (error) {
        console.error('Error occoured Try again!', error);
      })
      .finally(() => setLoading(false));
  };
}

export function deletOwnTask(id, setLoader, setModal, navigateHandler) {
  return (dispatch) => {
    db.collection('owntask')
      .doc(id)
      .delete()
      .then(function () {
        dispatch({
          type: DELETE_OWN_TASK,
          payload: id,
        });
        if (navigateHandler) {
          navigateHandler();
        }
        setModal(false);
        toast.success('Successfully Deleted');
      })
      .catch(function (error) {
        toast.error('Error occur, try again later');
        console.error(error);
      })
      .finally(() => {
        setLoader(false);
      });
  };
}

export function getTask({ employeeid, employeruid, setLoading }) {
  return (dispatch) => {
    try {
      db.collection('tasks')
        .where('uid', '==', employeruid)
        .where('employeeid', '==', employeeid)
        .onSnapshot(function (querySnapshot) {
          let datatoStore = [];
          let compTask = [];
          querySnapshot.forEach(function (doc) {
            const data = doc.data();
            const id = doc.id;
            if (data.DueTime !== 'Completed') {
              datatoStore.push({ id, ...data });
            } else {
              compTask.push({ id, ...data });
            }
            return { datatoStore, compTask };
          });
          dispatch({
            type: GET_COMP_TASK,
            payload: compTask,
          });
          dispatch(
            getEmpAssignedRepeatTask(
              employeeid,
              employeruid,
              datatoStore,
              setLoading
            )
          );
        });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
}

export function getEmpAssignedRepeatTask(
  employeeid,
  employeruid,
  preDatatoStore,
  setLoading
) {
  return (dispatch) => {
    try {
      db.collection('recurringTask')
        .where('uid', '==', employeruid)
        .where('employeeid', '==', employeeid)
        .onSnapshot(function (querySnapshot) {
          let datatoStore = [];
          querySnapshot.forEach(function (doc) {
            const data = doc.data();
            const id = doc.id;
            if (data.DueTime !== 'Completed') {
              datatoStore.push({ id, ...data });
            }
            return datatoStore;
          });
          dispatch({
            type: GET_TASK,
            payload: [...preDatatoStore, ...datatoStore],
          });
        });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
}

export function addEmpTask(
  data,
  clearState,
  setAddTaskLoader,
  navigateHandler
) {
  return async (dispatch) => {
    await axios
      .post(`${client_url}${taskApi.add_task}`, data)
      .then(() => {
        dispatch({
          type: ADD_TASK_EMPLOYEE,
          data: data,
        });
        if (navigateHandler) {
          navigateHandler();
        }
        clearState();
        toast.success('Task Successfully added');
      })
      .catch((err) => {
        console.log(err);
        toast.error('Error occured. Please try again later');
      })
      .finally(function () {
        setAddTaskLoader(false);
      });
  };
}
export function recurringTask(
  data,
  clearState,
  setAddTaskLoader,
  navigateHandler
) {
  return (dispatch) => {
    db.collection('recurringTask')
      .add(data)
      .then(function (docRef) {
        const id = docRef.id;
        // const Taskdata = docRef.data();
        const dataToStore = { id, ...data };
        dispatch({
          type: ADD_RECURRING_TASK,
          payload: dataToStore,
        });
        if (navigateHandler) {
          navigateHandler();
        }
        clearState();
        toast.success('Task added successfully');
      })
      .catch(function (error) {
        toast.error('Error occoured Try again!');
      })
      .finally(function () {
        setAddTaskLoader(false);
      });
  };
}
export function getAssignedTask(uid, setLoading) {
  return (dispatch) => {
    try {
      db.collection('tasks')
        .where('uid', '==', uid)
        .onSnapshot(function (querySnapshot) {
          let datatoStore = [];
          let compTask = [];
          querySnapshot.forEach(function (doc) {
            const data = doc.data();
            const id = doc.id;
            if (data.DueTime !== 'Completed') {
              datatoStore.push({ id, ...data });
            } else {
              compTask.push({ id, ...data });
            }
            return { datatoStore, compTask };
          });
          dispatch({
            type: GET_COMP_TASK,
            payload: compTask,
          });
          dispatch(repeatTask(uid, datatoStore, setLoading));
        });
    } catch (err) {
      console.error(err);
    }
  };
}

// get recurring/repeating tasks

export function repeatTask(uid, preDatatoStore, setLoading) {
  return (dispatch) => {
    try {
      db.collection('recurringTask')
        .where('uid', '==', uid)
        .onSnapshot(async function (querySnapshot) {
          let datatoStore = [];
          querySnapshot.forEach(function (doc) {
            const data = doc.data();
            const id = doc.id;
            datatoStore.push({ id, ...data });
            return datatoStore;
          });
          dispatch({
            type: GET_TASK,
            payload: [...preDatatoStore, ...datatoStore],
          });
        });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
}

export function deleteTask(id, setLoader, setModal, navigateHandler) {
  return (dispatch) => {
    db.collection('tasks')
      .doc(id)
      .delete()
      .then(function () {
        dispatch({
          type: DELETE_TASK,
          payload: id,
        });
        toast.success('Successfully Deleted');
        if (navigateHandler) {
          navigateHandler();
        }
      })
      .catch(function (error) {
        toast.error('Error occured! try again later');
        console.error(error);
      })
      .finally(() => {
        setLoader(false);
        setModal(false);
      });
  };
}

// delete recurring task
export function deleteRecurringTask(id, setLoader, setModal, navigateHandler) {
  return (dispatch) => {
    db.collection('recurringTask')
      .doc(id)
      .delete()
      .then(function () {
        dispatch({
          type: DELETE_TASK,
          payload: id,
        });
        toast.success('Task deleted successfully');
        if (navigateHandler) {
          navigateHandler();
        }
      })
      .catch(function (error) {
        toast.error('Error occured! try again later');
        console.error(error);
      })
      .finally(() => {
        setLoader(false);
        setModal(false);
      });
  };
}

export function updateTask(data, setLoading, setModal, navigateHandler) {
  return (dispatch) => {
    db.collection('tasks')
      .doc(data.id)
      .set(data)
      .then(function () {
        dispatch({
          type: UPDATE_TASK,
          payload: data,
        });
        if (navigateHandler) {
          navigateHandler();
        }
        setModal(false);
        toast.success('successfully updated');
      })
      .catch(function (error) {
        toast.error('Error ocurred! try again later');
      })
      .finally(() => {
        setLoading(false);
      });
  };
}

export function updateRecurringTask(
  data,
  setLoading,
  setModal,
  navigateHandler
) {
  return (dispatch) => {
    db.collection('recurringTask')
      .doc(data.id)
      .set(data)
      .then(function () {
        dispatch({
          type: UPDATE_TASK,
          payload: data,
        });
        if (navigateHandler) {
          navigateHandler();
        }
        setModal(false);
        toast.success('successfully updated');
      })
      .catch(function (error) {
        toast.error('Error ocurred! try again later');
      })
      .finally(() => {
        setLoading(false);
      });
  };
}

export function completedTask(
  data,
  setLoader,
  setModal,
  setCompletionNote
  // navigation
) {
  return (dispatch) => {
    db.collection('tasks')
      .doc(data.id)
      .set(data)
      .then(function (docRef) {
        // dispatch({
        //   type: COMPL_TASK,
        //   payload: data,
        // });
        setCompletionNote('');
        setModal(false);
        // if (navigation) {
        //   navigation();
        // }
        toast.success('Successfully Completed');
      })
      .catch(function (error) {
        console.error(error);
        toast.error('Error occured! try again later');
      })
      .finally(() => {
        setLoader(false);
      });
  };
}

export function getLogs(id) {
  return (dispatch) => {
    db.collection('taskLogs')
      .where('uid', '==', id)
      .onSnapshot(
        function (querySnapshot) {
          let datatoStore = [];
          querySnapshot.forEach(function (doc) {
            const data = doc.data();
            const key = doc.id;
            datatoStore.push({ ...data, key });

            return datatoStore;
          });

          dispatch({
            type: GET_ALL_LOG,
            payload: datatoStore,
          });
        },
        function (error) {
          console.log('error', error);
        }
      );
  };
}
export function verifyPhoneNumber(data) {
  return async (dispatch) => {
    await axios
      .post(`${client_url}${taskApi.send_verification_code}`, data)
      .then((res) => {
        const data = res.data;
        dispatch({
          type: VERIFY_PHONE_NO,
          payload: data,
        });
        toast.success('Verification Code send successfully');
      })
      .catch((err) => {
        console.log(err);
        toast.error('Error occured. Please try again later');
      });
  };
}
export function verifyOtpCode(data, setOtpDialog) {
  return async (dispatch) => {
    await axios
      .post(`${client_url}${taskApi.verify_otp}`, data)
      .then((res) => {
        if (res.data.verify) {
          dispatch({
            type: VERIFY_OTP,
            payload: data,
          });
          toast.success('Code verified Successfully');
        } else {
          toast.error('Wrong code');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Error occured. Please try again later');
      })
      .finally(() => setOtpDialog(false));
  };
}
