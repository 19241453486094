import { GIFT_CARDS } from '../actions/RewardAction';

var initialState = {
  rewards: [],
};

export function RewardReducer(state = initialState, action) {
  switch (action.type) {
    case GIFT_CARDS: {
      return {
        ...state,
        rewards: action.payload,
      };
    }
    default:
      return state;
  }
}
