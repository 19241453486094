export const GET_ASS_SHIFT = 'GET_ASS_SHIFT';
export const UPDATE_ASS_SHIFT = 'UPDATE_ASS_SHIFT';
import { db } from '../config/db';

export function getAssignedShiftsByEmployee({ id, setLoading }) {
  return (dispatch) => {
    db.collection('assignedShifts')
      .where('employeeid', '==', id)
      .onSnapshot(function (querySnapshot) {
        if (querySnapshot.empty) {
          setLoading(false);
        }
        let datatoStore = [];
        querySnapshot.forEach(function (doc) {
          const data = doc.data();
          const id = doc.id;
          datatoStore.push({ id, ...data });
        });
        setLoading(false);
        dispatch({
          type: GET_ASS_SHIFT,
          payload: datatoStore,
        });
      });
  };
}

export function contactEmployer({
  data,
  setLoading,
  setTitle,
  setDescription,
  setPurpose,
}) {
  return (dispatch) => {
    db.collection('contact')
      .add(data)
      .then(function () {
        toast.success('Successfully sent');
        setTitle('');
        setDescription('');
        setPurpose('schedule change');
      })
      .catch(function (error) {
        console.error(error);
        toast.error('Error occured! try again later');
      })
      .finally(() => setLoading(false));
  };
}
export function updateShifts(id, data, setLoading) {
  return (dispatch) => {
    db.collection('assignedShifts')
      .doc(id)
      .set(data)
      .then(function () {
        let datatoStore = {
          id,
          data,
        };

        toast.success('Shift updated successfully');
        dispatch({
          type: UPDATE_ASS_SHIFT,
          payload: datatoStore,
        });
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };
}
