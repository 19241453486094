import {
  LOGIN,
  ADD_NEW_EMPLOYEEERR,
  GET_CURRENT_EMPLOYEE,
  EMPLOYEELOGOUT,
  GET_EMPLOYEE_DATA,
  MANAGE_EMP_PIN,
  UPDATE_USER,
} from '../actions/AuthActions';

var initialState = {
  userStatus: false,
  user: {},
  data: {},
  submitTicketStatus: false,
};

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        userStatus: true,
        user: action.payload,
      };
    }
    case GET_CURRENT_EMPLOYEE: {
      return {
        ...state,
        userStatus: true,
        user: action.payload,
      };
    }
    case UPDATE_USER: {
      const data = action.payload;
      const updatedUser = { ...state.user, ...data };
      return {
        ...state,
        user: updatedUser,
      };
    }
    case ADD_NEW_EMPLOYEEERR: {
      return {
        ...state,
      };
    }
    case EMPLOYEELOGOUT: {
      return {
        ...state,
        userStatus: false,
      };
    }

    case GET_EMPLOYEE_DATA: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case MANAGE_EMP_PIN: {
      return {
        ...state,
        user: { ...state.user, pin: action.payload },
      };
    }
    default:
      return state;
  }
};
