import { ACTIVE_TAB, SEARCH_QUERY } from '../actions/CommonAction';

var initialState = {
  activeTab: '',
  searchQuery: '',
};

export function CommonReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload,
      };
    }
    case SEARCH_QUERY: {
      return {
        ...state,
        searchQuery: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
