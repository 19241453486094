import React from 'react'
import { Helmet } from 'react-helmet'
import UseSiteMetadata from '../../hooks/useSiteMetadata'
export default function SEO(props) {
    const { title: titleMain, description: descriptionMain } = UseSiteMetadata()
    const { title, description } = props
    return (
        <Helmet>
            <title>{title || titleMain}</title>
            <meta name="title" content={title || titleMain} />
            <meta name="description" content={description || descriptionMain} />
        </Helmet>
    )
}
