import { combineReducers } from 'redux';
import { AuthReducer } from './AuthReducer';
import { StatesReducer } from './StatesReducer';
import { EmployeeTaskReducer } from './EmployeeTaskReducer';
import { PaperWorkReducer } from './PaperWorkReducer';
import { SettingsReducer } from './SettingReducer';
import { ShiftReducer } from './ShiftReducer';
import { AttendanceReducers } from './AttendanceReducer';
import { EmployerReducer } from './EmployerReducer';
import { ScheduleReducer } from './ScheduleReducer';
import { TicketReducer } from './TicketReducer';
import { PaystubReducer } from './PaystubReducer';
import { ThemeReducer } from './ThemeReducer';
import { CommonReducer } from './CommonReducer';
import { RewardReducer } from './RewardReducer';

const reducers = combineReducers({
  AuthReducer,
  StatesReducer,
  EmployeeTaskReducer,
  PaperWorkReducer,
  SettingsReducer,
  ShiftReducer,
  AttendanceReducers,
  EmployerReducer,
  ScheduleReducer,
  TicketReducer,
  PaystubReducer,
  ThemeReducer,
  CommonReducer,
  RewardReducer,
});
const rootReducer = (state, action) => {
  return reducers(state, action);
};
export default rootReducer;
