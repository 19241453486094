import {
  GET_STATES,
  GET_INDUSTRY,
  GET_WC_STATES,
} from '../actions/StatesActions';

var initialState = {
  states: [],
  industries: [],
  wcState: [],
};

export const StatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATES: {
      return {
        ...state,
        states: action.payload,
      };
    }
    case GET_INDUSTRY: {
      return {
        ...state,
        industries: action.payload,
      };
    }
    case GET_WC_STATES: {
      return {
        ...state,
        wcState: action.payload,
      };
    }
    default:
      return state;
  }
};
