import React, { useState } from "react"
import { Link } from "gatsby"
import logo from "../../assets/images/helpara/logo.png"
import { useLocation } from "@reach/router"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import CircularProgress from "@mui/material/CircularProgress"
import { } from "@shared-store"
function Footer({ className }) {
  const location = useLocation()
  const [login, setLogin] = React.useState(false)
  const [name, setName] = useState("")
  const [subject, setSubject] = useState("")
  const [comment, setComment] = useState("")
  const [email, setEmail] = useState("")
  const [spinner, setSpinner] = useState(false)
  const validateEmail = email => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-z  A-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const onSendMessageHandler = e => {
    e.preventDefault()
    if (!email || !subject || !comment || !name) {
      return toast.error("Please Type all Field")
    } else if (!validateEmail(email)) {
      return toast.error("Please Type Valid Email")
    } else {
      setSpinner(true)
      const data = {
        name,
        email,
        subject,
        comment,
      }
      axios
        .post("https://api.helpara.com/contactUs/contactUsApi", data)
        .then(response => {
          if (response.status === 200) {
            toast.success("Message Send")
            setSpinner(false)
            setName("")
            setEmail("")
            setSubject("")
            setComment("")
          }
        })
        .catch(e => {
          toast.error("Please Try Again")
          setSpinner(false)
        })
    }
  }
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div>
      <section id="footer" className={`unoHR-footer-area ${className || ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-about-widget">
                <div className="unoHR-logo-box">
                  <a href="/">
                    <img
                      src={logo}
                      alt=""
                      style={{ width: "17vh", height: "6vh" }}
                    />
                  </a>
                  <p style={{ color: "grey", fontSize: "13px" }}>
                    helpara is all-in-one innovative, simple to houshold
                    employees management software that helps employers with all
                    the features needed to manage onboarding, background checks,
                    task manager, timesheets, payroll and employee rewards. Our
                    cloud bases platform is easy to use and can be run entirely
                    from your mobile phone (IOS & Android). Alerts and
                    notifications, makes it easier not to miss daily tasks &
                    deadlines.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="footer-navigation">
                <h4 className="title">Support</h4>
                <ul>
                  <li>
                    <Link
                      to={
                        location.pathname !== "/" ? "/#features" : "#features"
                      }
                    >
                      Features
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={
                        location.pathname !== "/" ? "/#pricings" : "#pricings"
                      }
                    >
                      Pricing
                    </Link>
                  </li>
                  <li>
                    <Link to={location.pathname !== "/" ? "/#blog" : "#blog"}>
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to={location.pathname !== "/" ? "/#faq" : "#faq"}>
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <Link to="#contact">Contact</Link>
                  </li>
                  <li>
                    <a
                      className="login-btn"
                      href="#contact"
                      onClick={() => setLogin(value => !value)}
                    >
                      Login
                    </a>
                    {login && (
                      <ul className="sub-menu">
                        <li>
                          <Link href="https://app.helpara.com/">Employer</Link>
                        </li>
                        <li>
                          <Link href="https://app.helpara.com/">Employee</Link>
                        </li>
                      </ul>
                    )}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-6" id="contact">
              <div className="pt-20">
                <h4 className="title pb-20">Contact</h4>
                <div className="text-center mx-auto g-width-70x--md">
                  <form>
                    <div className="row">
                      <div className="col-md-6 form-group g-mb-30">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Your Name"
                          value={name}
                          onChange={e => setName(e.target.value)}
                        />
                      </div>

                      <div className="col-md-6 form-group g-mb-30">
                        <input
                          className="form-control g-color-gray-dark-v5 g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--focus g-rounded-20 g-py-13 g-px-15"
                          type="email"
                          placeholder="Your Email"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                        />
                      </div>

                      <div className="col-md-12 form-group g-mb-30">
                        <input
                          className="form-control g-color-gray-dark-v5 g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--focus g-rounded-20 g-py-13 g-px-15"
                          type="text"
                          placeholder="Subject"
                          value={subject}
                          onChange={e => setSubject(e.target.value)}
                        />
                      </div>

                      <div className="col-md-12 form-group g-mb-30">
                        <textarea
                          className="form-control g-color-gray-dark-v5 g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--focus g-resize-none g-rounded-20 g-py-13 g-px-15"
                          rows="7"
                          placeholder="Your Comments"
                          value={comment}
                          onChange={e => setComment(e.target.value)}
                        ></textarea>
                      </div>
                    </div>

                    <div className="text-center">
                      <button
                        className="btn main-btn"
                        type="submit"
                        onClick={onSendMessageHandler}
                        style={{ backgroundColor: "#397e3d", color: "white" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {spinner ? (
                            <span style={{padding:"0px 15px 0px 15px",marginTop:3}}>
                              <CircularProgress color="inherit" size={"18px"} />
                            </span>
                          ) : (
                            "Send Message"
                          )}
                        </div>
                      </button>
                      {/* 
                      <div
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                        className="toast"
                        data-autohide="false"
                      >
                        <div className="toast-header">
                          <strong className="mr-auto">Bootstrap</strong>
                          <small>11 mins ago</small>
                          <button
                            type="button"
                            className="ml-2 mb-1 close"
                            data-dismiss="toast"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="toast-body">
                          Hello, world! This is a toast message.
                        </div>
                      </div> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="copyright">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="footer-copyright d-flex align-items-center justify-content-between">
                    <div className="copyright-text" style={{ color: "grey" }}>
                      <p>helpara is registered trademarks of WorkScaler Inc</p>
                      <p>
                        {" "}
                        <ul>
                          <Link className="link" to="/ThirdParty">
                            © {new Date().getFullYear()} WorkScaler inc. | All third party trademarks
                            are the property of their respective owners.
                          </Link>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="copyright-menu footer-copyright d-flex align-items-center justify-content-between pt-35">
                    <ul>
                      <li onClick={scrollToTop}>
                        <Link className="copyright-txt" to="/CaPrivacy">
                          CA Privacy Rights
                        </Link>
                      </li>
                      <li onClick={scrollToTop}>
                        <Link className="copyright-txt" to="/PrivacyPolicy">
                          | Privacy Policy
                        </Link>
                      </li>
                      <li onClick={scrollToTop}>
                        <Link className="copyright-txt" to="/Terms">
                          | Terms and Service
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Footer
