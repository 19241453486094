
import React, { memo } from 'react'
import * as styles from '../../styles/components/Details.module.css'
import twitter from '../../assets/twitter.svg'
import facebook from '../../assets/facebook.svg'
import linkedin from '../../assets/linkedin.svg'
import copy from '../../assets/copy.svg'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
// import { GatsbyImage, getImage } from 'gatsby-plugin-image'
// import BlogCard from '../blogCard/BlogCard'
// import { CopyToClipboard } from 'react-copy-to-clipboard'
import reddit from '../../assets/reddit.png'

import { useLocation } from '@reach/router'

const Details = memo(({ singleBlog, data }) => {
  const allBlogs = data
  const blog = singleBlog
  const newBlog = allBlogs.filter((item) => item.node.id !== blog.id)
  const location = useLocation()
  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const { gatsbyImageData } = node.data.target
        if (!gatsbyImageData) {
          return null
        }
        return <div className={styles.blogImg}><img src={gatsbyImageData} /></div>
      },
    },
  }
  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.subContainer}>
          <h1 className={styles.heading}>{blog?.title}</h1>
          <p className={styles.date}>{new Date(blog?.updatedAt).toLocaleDateString()}</p>
          <div className={styles.tagsContainer}>
            {
              blog?.tags?.map((tag, index) => {
                return <div key={index} className={styles.contentBg}>
                  <p className={styles.tag}>#{tag}</p>
                </div>
              })
            }
          </div>
        </div>
        <div className={styles.thumbnailCont}>
          <img src={blog?.thumbnail?.file?.url} />
        </div>
        <div className={styles.container}>
          <div className={styles.sideIcons}>
            <p className={styles.share}>SHARE</p>
            <div className={styles.iconContainer} >
              <span
                style={{ cursor: "pointer" }}
                onClick={
                  () => window.location.href = `https://www.facebook.com/sharer/sharer.php?u=https://resumas.com${location.pathname}`}>
                <img
                  src={facebook}
                  alt='facebook'
                  width={29}
                  height={33}
                />
              </span>
              <span style={{ cursor: "pointer" }}
                onClick={() => window.location.href = `https://twitter.com/intent/tweet?text=${blog?.title}&url=https://resumas.com${location.pathname}&hashtags=`}>
                <img
                  src={twitter}
                  alt='twitter'
                  width={23}
                  height={23}
                />
              </span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => window.location.href = `https://www.linkedin.com/sharing/share-offsite/?url=https://resumas.com${location.pathname}`}>
                <img
                  src={linkedin}
                  alt='linkedIn'
                  width={23}
                  height={23}
                />
              </span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => window.location.href = `https://www.reddit.com/login/?dest=https%3A%2F%2Fwww.reddit.com%2Fsubmit%3Furl%3Dhttps://resumas.com${location.pathname}%26title%3D${blog?.title}`}>
                <img
                  src={reddit}
                  alt='linkedIn'
                  width={23}
                  height={23}
                />
              </span>
              {/* <CopyToClipboard text={`https://resumas.com${location.pathname}`} onCopy={() => alert("link copied")}>
                <div >
                  <img src={copy} alt='copy' style={{ cursor: 'pointer' }} width={20} height={20} />
                </div>
              </CopyToClipboard> */}
            </div>
          </div>
          <div className={styles.container1}>
            <div className={styles.contentContainer}>
              {renderRichText(blog?.body, options)}
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* <center style={{ marginTop: 100 }}>
          <h3 className='subTitleS'>Related Blogs</h3>
        </center> */}
        {/* <div className={styles1.container}>
          {
            newBlog?.slice(0, 3).map((item, index) => {
              return (
                <div key={index}>
                  <BlogCard cardData={item} index={index} />
                </div>
              )
            })
          }
        </div> */}
      </div>
    </>
  )
})




export default Details;
