import { db } from '../config/db';
export const GET_STATES = 'GET_STATES';
export const GET_INDUSTRY = 'GET_INDUSTRY';
export const GET_WC_STATES = 'GET_WC_STATES';

export function getStates() {
  return (dispatch) => {
    db.collection('wcStates')
      .doc('one')
      .get()
      .then(function (doc) {
        let dataToStore = [];
        if (doc.data()) {
          const data = doc.data();
          dataToStore = data['wcStates'];
          dispatch({
            type: GET_STATES,
            payload: dataToStore,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };
}
export const getIndustry = () => {
  return (dispatch) => {
    db.collection('industry')
      .get()
      .then(function (doc) {
        let firebaseData = [];
        doc.forEach((docRecord) => {
          let data = docRecord.data();
          firebaseData.push(data);
        });
        dispatch({
          type: GET_INDUSTRY,
          payload: firebaseData,
        });
      })
      .catch(function (error) {
        console.error(error);
      });
  };
};

export const getWcStates = (setLoading) => {
  return (dispatch) => {
    db.collection('wcStates')
      .doc('one')
      .get()
      .then(function (doc) {
        let dataToStore = [];
        if (doc.data()) {
          const data = doc.data();
          dataToStore = data['wcStates'];

          dispatch({
            type: GET_WC_STATES,
            payload: dataToStore,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };
};
