import { db } from '../config/db';
export const GET_PAY_STUB = 'GET_PAY_STUB';
export const GETEMPLOYERPAYSTUBS = 'GETEMPLOYERPAYSTUBS';
export function getStartPayStubs(id, setLoading) {
  return async (dispatch) => {
    db.collection('paystubs')
      .where('employeeUid', '==', id)
      .onSnapshot(function (querySnapshot) {
        let datatoStore = [];
        querySnapshot.forEach(function (doc) {
          const data = doc.data();
          const id = doc.id;
          datatoStore.push({ id, ...data });
        });
        dispatch({
          type: GET_PAY_STUB,
          payload: datatoStore,
        });
        setLoading(false);
      });
  };
}
export function getEmployerPayStubs(id) {
  return async (dispatch) => {
    db.collection('paystubs')
      .where('employerUid', '==', id)
      .onSnapshot(
        function (querySnapshot) {
          let datatoStore = [];
          querySnapshot.forEach(function (doc) {
            const data = doc.data();
            const id = doc.id;
            datatoStore.push({ id, ...data });
          });
          dispatch({
            type: GETEMPLOYERPAYSTUBS,
            payload: datatoStore,
          });
        },
        function (error) {
          console.error(error);
        }
      );
  };
}
