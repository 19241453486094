import { graphql } from "gatsby"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { ToastContainer } from "react-toastify"
import Blogs from "../components/blogs/Blogs"
import Faqs from "../components/faq/Faqs"
import Features from "../components/features/Features"
import Header from "../components/header/Header"
import Layout from "../components/Layout"
import Pricing from "../components/pricing/Pricing"
import SEO from "../components/seo/SEO"
import Services from "../components/services/Services"
import Traffic from "../components/traffic/Traffic"
import "../styles/global.css"
const Index = (({ data }) => {
  const [Data, setData] = useState(null)
  useEffect(()=>{

    var blogs = <Blogs data={data} />
    setData(data)
  })

  return (
    <div>
      <Layout>
        <SEO />
        <Header />
        <Services />
        <Features />
        <Traffic />
        <Pricing />
        <Faqs />
        {/* {blogs} */}
        <Blogs data={Data} />
        <ToastContainer />
      </Layout>
    </div>
  )
})
export const query = graphql`
  query {
    allContentfulResumasBlog {
      edges {
        node {
          id
          title
          shortDescription
          slug
          updatedAt(locale: "")
          thumbnail {
            title
            file {
              url
            }
            id
          }
          tags
          body {
            references {
              file {
                url
              }
            }
            raw
          }
        }
      }
    }
  }
`
export default Index
