import { GET_PRIVACY_POLICY, GET_TERMS_CONDITIONS } from '../actions/SettingsActions';
var initialState = {
  statesWages: [],
  industry: [],
  termsConditions: {},
  privacyPolicy: {},
};

export function SettingsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRIVACY_POLICY: {
      return {
        ...state,
        privacyPolicy: action.payload,
      };
    }
    case GET_TERMS_CONDITIONS: {
      return {
        ...state,
        termsConditions: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
