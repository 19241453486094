import { db } from '../config/db';
export const EMPLOYEE_CONTACTS = 'EMPLOYEE_CONTACTS';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const READ_MESSAGE = 'READ_MESSAGE';
export const ADD_SHIFT = 'ADD_SHIFT';
export const GET_SHIFT = 'GET_SHIFT';
export const ASSIGN_SHIFT = 'ASSIGN_SHIFT';
export const GET_ALL_ASS_SHIFT = 'GET_ALL_ASS_SHIFT';

export function employeeContacts(id) {
  return (dispatch) => {
    db.collection('contact')
      .where('employeruid', '==', id)
      .onSnapshot(
        function (querySnapshot) {
          let datatoStore = [];
          querySnapshot.forEach(function (doc) {
            const data = doc.data();
            const id = doc.id;
            datatoStore.push({ id, ...data });

            return datatoStore;
          });
          dispatch({
            type: EMPLOYEE_CONTACTS,
            payload: datatoStore,
          });
        },
        (error) => {
          console.error(error);
        }
      );
  };
}
export function readMessage(id) {
  return (dispatch) => {
    db.collection('contact')
      .doc(id)
      .update({ read: true })
      .then(() => {
        dispatch({
          type: READ_MESSAGE,
          payload: id,
        });
      })
      .catch((err) => {
        console.log('eer', err);
      });
  };
}
export function deleteMessage(id, setLoading) {
  return (dispatch) => {
    db.collection('contact')
      .doc(id)
      .delete()
      .then(() => {
        toast.success('Successfully deleted message');

        dispatch({
          type: DELETE_MESSAGE,
          payload: id,
        });
      })
      .catch((err) => {
        console.log('eer', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
}
export function addShift(
  data,
  setaddShiftLoader,
  setShiftName,
  setColor,
  setStartTime,
  setEndTime,
  navigateHandler
) {
  return (dispatch) => {
    db.collection('shifts')
      .add(data)
      .then(function (docRef) {
        const id = docRef.id;
        const dataToStore = { id, ...data };
        dispatch({
          type: ADD_SHIFT,
          payload: dataToStore,
        });
        setaddShiftLoader(false);
        setShiftName('');
        setColor('');
        setEndTime('');
        setStartTime('');
        if (navigateHandler) {
          navigateHandler();
        }
        toast.success('Shift Added successfully');
      })
      .catch(function (error) {
        setaddShiftLoader(false);
        toast.error('Error occured. Please try again later');
      });
  };
}

export function getShifts(id) {
  return (dispatch) => {
    db.collection('shifts')
      .where('employeruid', '==', id)
      .onSnapshot(
        function (querySnapshot) {
          let datatoStore = [];
          querySnapshot.forEach(function (doc) {
            const data = doc.data();
            const id = doc.id;
            datatoStore.push({ id, ...data });

            return datatoStore;
          });
          dispatch({
            type: GET_SHIFT,
            payload: datatoStore,
          });
        },
        (error) => {
          console.error(error);
        }
      );
  };
}

export function getAllAssignedShifts(id, setLoading) {
  return (dispatch) => {
    db.collection('assignedShifts')
      .where('employerid', '==', id)
      .onSnapshot(
        function (querySnapshot) {
          let datatoStore = [];
          querySnapshot.forEach(function (doc) {
            const data = doc.data();
            const id = doc.id;
            datatoStore.push({ id, ...data });
          });
          dispatch({
            type: GET_ALL_ASS_SHIFT,
            payload: datatoStore,
          });
          setLoading(false);
        },
        (error) => {
          console.error(error);
          setLoading(false);
        }
      );
  };
}
export function assignShift(
  data,
  setButtonLoader,
  goBackHandler,
  setShift,
  setEmployee
) {
  return (dispatch) => {
    db.collection('assignedShifts')
      .add(data)
      .then(function (docRef) {
        const id = docRef.id;
        const dataToStore = { id, ...data };
        setShift('');
        setEmployee('');
        dispatch({
          type: ASSIGN_SHIFT,
          payload: dataToStore,
        });
        setButtonLoader(false);
        if (goBackHandler) {
          goBackHandler();
        }
        toast.success('Shift Assigned successfully');
      })

      .catch(function () {
        toast.error('Error occured. Please try again later');
      });
  };
}
