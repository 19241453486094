import { graphql } from 'gatsby';
import React from 'react'
import Blogs from '../components/blogs/Blogs';
import Layout from '../components/Layout';

function blogs({ data }) {
  const [Data, setData] = React.useState(null)
  React.useEffect(()=>{
    setData(data)
  })
  return (
    <Layout>
      <div style={{marginTop:100}}>
        <Blogs data={Data} />
      </div>
    </Layout>

  )
}

export const query = graphql`
  query  {
    allContentfulResumasBlog {
      edges {
        node {
          id
          title
          shortDescription
          slug
          updatedAt(locale: "")
          thumbnail {
            title
            file {
              url
            }
            id
          }
          tags
          body {
            references {
              file {
                url
              }
            }
            raw
          }
        }
      }
    }
  }
`;
export default blogs;
