import React, { memo } from "react"
import { graphql } from "gatsby"
import SEO from "../seo/SEO"
import Layout from "../Layout"
import Details from "../blogs/Details"

//
function BlogLayout({ data }) {
  const blog = data?.blog?.edges?.[0]?.node
  const allBlogs = data?.allBlog?.edges
  return (
    <div>
      <SEO
        tags={blog?.tags}
        title={blog?.title}
        image={`https:${blog?.thumbnail?.file?.url}`}
        description={blog?.shortDescription}
      />
      <Layout>
        <Details singleBlog={blog} data={allBlogs} />
      </Layout>
    </div>
  )
}

export const query = graphql`
  query ($slug: String) {
    blog: allContentfulResumasBlog(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          title
          shortDescription
          slug
          updatedAt(locale: "")
          thumbnail {
            title
            file {
              url
            }
            id
          }
          tags
          body {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                title
                file {
                  url
                }
                description
                gatsbyImageData(width: 1000)
                __typename
              }
            }
          }
        }
      }
    }
    allBlog: allContentfulResumasBlog {
      edges {
        node {
          id
          title
          shortDescription
          slug
          updatedAt(locale: "")
          thumbnail {
            title
            file {
              url
            }
            id
          }
          tags
          body {
            references {
              file {
                url
              }
            }
            raw
          }
        }
      }
    }
  }
`

export default memo(BlogLayout);
