import {
  EMPLOYEE_CONTACTS,
  ADD_SHIFT,
  GET_SHIFT,
  ASSIGN_SHIFT,
  GET_ALL_ASS_SHIFT,
  DELETE_MESSAGE,
  READ_MESSAGE,
} from '../actions/ScheduleAction';

const initialState = {
  allSchadule: [],
  loader: new Date(),
  editSchadule: {},
  employeeContacts: [],
  shifts: [],
  assignedShifts: [],
};
export const ScheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case EMPLOYEE_CONTACTS:
      return {
        ...state,
        loader: new Date(),
        employeeContacts: action.payload,
      };
    case READ_MESSAGE:
      const updatedEmployeeContact = state.employeeContacts.map((contact) => {
        if (contact.employeeid === action.payload.id) {
          return {
            ...state.employeeContacts,
            read: true,
          };
        }
        return contact;
      });
      return {
        ...state,
        employeeContacts: updatedEmployeeContact,
      };
    case DELETE_MESSAGE:
      let newMessages = state.employeeContacts.filter(
        (item) => item.id !== action.payload
      );

      return {
        ...state,
        loader: new Date(),
        employeeContacts: newMessages,
      };
    case ADD_SHIFT: {
      return {
        ...state,
        shifts: [...state.shifts, action.payload],
      };
    }
    case GET_SHIFT: {
      return {
        ...state,
        shifts: action.payload,
      };
    }

    case GET_ALL_ASS_SHIFT: {
      return {
        ...state,
        assignedShifts: action.payload,
      };
    }
    case ASSIGN_SHIFT: {
      const assignedShifts = [...state.assignedShifts];
      const alreadyExists = assignedShifts.find(
        (shift) => shift.id === action.payload.id
      );
      if (!alreadyExists) {
        assignedShifts.push(action.payload);
      }
      return {
        ...state,
        assignedShifts,
      };
    }
    default: {
      return state;
    }
  }
};
