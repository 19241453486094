import axios from 'axios';

export const ACTIVE_TAB = 'ACTIVE_TAB';
export const SEARCH_QUERY = 'SEARCH_QUERY';

export function setActiveTab(routeName) {
  return (dispatch) => {
    dispatch({
      type: ACTIVE_TAB,
      payload: routeName,
    });
  };
}

export function setSearchQuery(searchQuery) {
  return (dispatch) => {
    dispatch({
      type: SEARCH_QUERY,
      payload: searchQuery,
    });
  };
}

export function fetchDummyData(isConnected, setIsConnected, setLoading) {
  return async (dispatch) => {
    await axios
      .get('https://randomuser.me/api/?results=30')
      .then(({ data }) => {
        const { results } = data;
        //  setUsers(results);
        console.log('results', results);
        isConnected && setIsConnected(false);
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
}
