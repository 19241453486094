import { db } from '../config/db';
export const ADD_TICKET = 'ADD_TICKET';
export const GET_ALL_MY_TICKETS = 'GET_ALL_MY_TICKETS';

export function addTicket(data, setLoading, clearData, navigateHandler) {
  return (dispatch) => {
    db.collection('supportTickets')
      .add(data)
      .then(function (docRef) {
        const id = docRef.id;
        const dataToStore = { id, ...data };
        clearData();
        dispatch({
          type: ADD_TICKET,
          payload: dataToStore,
        });
        navigateHandler && navigateHandler();
        toast.success('Ticket added successfully');
      })
      .catch(function (error) {
        console.error('Error occoured Try again!', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
}

export function getTickets(id, setLoading) {
  return (dispatch) => {
    db.collection('supportTickets')
      .where('uid', '==', id)
      .get()
      .then(function (querySnapshot) {
        var allTickets = [];
        querySnapshot.forEach(function (doc) {
          allTickets.push({ docId: doc.id, ...doc.data() });
        });
        dispatch({ type: GET_ALL_MY_TICKETS, payload: allTickets });
        setLoading(false);
      });
  };
}
