import { db } from '../config/db';
export const GET_EMPLOYEE_PAPER_WORK = 'GET_EMPLOYEE_PAPER_WORK';
export const ASSING_PAPERWORK = 'ASSING_PAPERWORK';
export const GET_ALL_MY_SUBMISSION = 'GET_ALL_MY_SUBMISSION';
export const GET_PDF_RECORDS = 'GET_PDF_RECORDS';

export function getEmployeeDocs({ empId, setLoading }) {
  return (dispatch) => {
    try {
      db.collection('paperworktask')
        .where('emp_id', '==', empId)
        .onSnapshot(function (querySnapshot) {
          let datatoStore = [];
          querySnapshot.forEach(function (doc) {
            const data = doc.data();
            const id = doc.id;
            datatoStore.push({ id, ...data });
          });
          dispatch({
            type: GET_EMPLOYEE_PAPER_WORK,
            payload: datatoStore,
          });
        });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
}
export function addPaperWork(data, setLoader, navigation) {
  return (dispatch) => {
    db.collection('paperworktask')
      .add(data)
      .then(function (docRef) {
        const id = docRef.id;
        const dataToStore = { id, ...data };
        toast.success('Task added successfully');
        dispatch({
          type: ASSING_PAPERWORK,
          payload: dataToStore,
        });
        navigation && navigation.goBack();
      })
      .catch(function (error) {
        console.error('Error occoured Try again!', error);
      })
      .finally(() => setLoader(false));
  };
}

export function getAllSubmissions(empId, setLoading, key = 'employerId') {
  return (dispatch) => {
    try {
      setLoading(true);
      db.collection('submission')
        .where(key, '==', empId)
        .onSnapshot(function (querySnapshot) {
          let datatoStore = [];
          querySnapshot.forEach(function (doc) {
            const data = doc.data();
            const id = doc.id;
            datatoStore.push({ id, ...data });
          });
          dispatch({
            type: GET_ALL_MY_SUBMISSION,
            payload: datatoStore,
          });
        });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
}

export function getPdfRecords(id, setLoading) {
  return (dispatch) => {
    setLoading(true);
    db.collection('pdfRecords')
      .where('employerid', '==', id)
      .onSnapshot(
        function (querySnapshot) {
          let pdfs = [];
          querySnapshot.forEach(function (doc) {
            const data = doc.data();
            const id = doc.id;

            pdfs.push({ ...data, id });
          });

          setLoading(false);
          dispatch({
            type: GET_PDF_RECORDS,
            payload: pdfs,
          });
        },
        function (error) {
          console.error(error);
          setLoading(false);
        }
      );
  };
}
